import React from "react";
import styled from "styled-components";
import withLayout from "../../layout";
import {FormattedMessage, injectIntl} from "react-intl";
//COMPONENTS
import SimpleStats from "../../components/UI/SimpleStats";
import Image from "../../components/UI/Image";
//GENERIC STYLE IMPORT
import {Heading, Paragraph} from "../../components/UI/Typography";
import DropBottom from '../../images/case_studies/audi/DropBottom.svg';
import DropUp from '../../images/case_studies/audi/DropUp.svg';
import BKGImg from "../../images/case_studies/audi/banner-audi.jpg";
import HeroCaseStudy from "../../components/HeroCaseStudy";
import LottieController from "../../components/UI/LottieController";
import {useInView} from "react-intersection-observer";
import DropBg from "../../components/UI/DropBg";
import DropBig1 from '../../images/case_studies/acea/DropBig1.svg';
import DropBig2 from '../../images/case_studies/acea/DropTopLeftM.svg';
import SEO from "../../components/SEO";
import Result1 from '../../images/case_studies/audi/Icona Volante.json';
import Result2 from '../../images/case_studies/audi/Icona Sorriso diretta.json';
import Result3 from '../../images/case_studies/audi/Icona Crono.json';
import {Fade} from 'react-reveal';
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory";
import SectionTitle from "../../components/CaseStudy/SectionTitle";
import Results from "../../components/CaseStudy/Results";
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage"

const StatsDiv = styled.div`
margin-top: 32px;
h3{
color:#000000;
font-weight:700;
font-style:normal;
font-size:32px;
margin:0px;
}
p{
    color:#2266A4;
    font-size:18px;
    font-weight:700;
}
`

const CompanyStats = () => {
    return <StatsDiv>
        <h3>70,39 Mln </h3>
        <p style={{color: '#515151'}}>
            <FormattedMessage id={'audi.stats_1_msg'}/>
        </p>
    </StatsDiv>
}

const AudiCaseStudy = ({intl}) => {
    const [graphView1, inViewG1] = useInView();
    const [graphView2, inViewG2] = useInView();
    const [graphView3, inViewG3] = useInView();
    const keywords = intl.formatMessage({id: 'audi.meta_keywords'}).split(',') || [];

    return (
        <>
            <SEO title={intl.formatMessage({id: 'audi.meta_title'})}
                 description={intl.formatMessage({id: 'audi.meta_description'})} keywords={keywords}
                 lang={intl.locale}/>
            <HeroCaseStudy hasPartner={false} heroImage={'case_studies/audi/Header-Audi.png'} title={'audi.title'}
                           stats={<CompanyStats/>} subtitle1={'audi.subtitle'}
                           color={'linear-gradient(#000000 0%, #515151 100%)'} intl={intl}
                           categories={['portfolio.filters.data_analysis_consulting']}/>
            <div className="section-padding">
                <DropBg src={DropBig1} left={'0%'} zIndex={'-1'} bottom={'-20%'} className="d-none d-lg-block"/>
                <DropBg src={DropUp} bottom={'5%'} left={'25%'} className="d-none d-lg-block"/>
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle number={'01'} text={'audi.overview_title'}/>
                            </div>
                        </Fade>
                    </div>
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className={"col-md-5 col-12"}>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"audi.client_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"audi.client_1"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={600}>
                            <div className={"col-md-6 col-12 offset-md-1"}>
                                <Heading level={"4"} className="pt-2">
                                    <FormattedMessage id={"audi.client_needs"}/>
                                </Heading>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"audi.client_needs_1"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <FullWidthImage image={BKGImg} imageWidth={2468} imageHeight={1646}/>
            <div className="section-padding">
                <DropBg src={DropBig2} right={'-10%'} zIndex={'-1'} top={'15%'} className="d-none d-lg-block"/>
                <DropBg src={DropBottom} top={'10%'} right={'10%'} className="d-none d-lg-block"/>
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 d-none d-md-block">
                                <SectionTitle number={'02'} text={'audi.brief_title'}/>
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <Heading level={"2"} margin={'0px'}>
                                    <FormattedMessage id={"audi.service_big_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id="audi.services_txt_1"/>
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle number={'03'} text={'audi.workflow_title'}/>
                            </div>
                        </Fade>
                    </div>
                    <div className="row ">
                        <Fade bottom delay={400}>
                            <div className="col-md-7 col-sm-12">
                                <Heading level={"3"}>
                                    <FormattedMessage id={"audi.solutions_big_title"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"audi.solutions_txt_1"}/>
                                </Paragraph>
                                <Heading level={"4"}>
                                    <FormattedMessage id={"audi.solutions_title_2"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"audi.solutions_txt_2"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={600}>
                            <div className="col-md-4 col-sm-12 offset-0 offset-md-1 position-relative">
                                <Image filename={'case_studies/audi/Body-audi.jpg'} alt={'body audi'}
                                       className={'case-study-right-pic'}/>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="section-padding-custom">
                <div className="container">
                    <div className="row align-items-start">
                        <Fade bottom delay={400}>
                            <div className="col-md-7 col-sm-12 order-last order-md-first d-md-flex d-none position-relative">
                                <Image filename={'case_studies/audi/Board-audi.jpg'} imgClassName="section-image" alt={'board audi'}/>
                            </div>
                        </Fade>
                        <div className="col-md-5 col-12 col-sm-12 order-first order-md-last">
                            <Fade bottom delay={600}>
                                <div>
                                    <Heading level={"4"}>
                                        <FormattedMessage id={"audi.solutions_title_3"}/>
                                    </Heading>
                                    <Paragraph>
                                        <FormattedMessage id={"audi.solutions_txt_3"}/>
                                    </Paragraph>
                                </div>
                            </Fade>
                            <Fade bottom delay={800}>
                                <div>
                                    <Heading level={"4"}>
                                        <FormattedMessage id={"audi.solutions_title_4"}/>
                                    </Heading>
                                    <Paragraph>
                                        <FormattedMessage id={"audi.solutions_txt_4"}/>
                                    </Paragraph>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
            <Results
                subtitle="audi.dark_section_paragraph"
                testimonialQuote="audi.dark_section_quote"
                testimonialImg="manuel_fontana.jpg"
                testimonialName="audi.dark_section_testimonial_name"
                testimonialRole="audi.dark_section_testimonial_desc"
            >
                <div className="col-12 col-lg-3">
                    <div ref={graphView1}>
                        <LottieController
                            lottieJSON={Result1}
                            inView={inViewG1}
                            height="99px"
                            width="99px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG1}
                            valueFrom={0}
                            valueTo={7}
                            prefix="+"
                            headingStyle={{fontWeight: 500}}
                            measureUnit="%"
                            label="audi.stats_1"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView2}>
                        <LottieController
                            lottieJSON={Result2}
                            inView={inViewG2}
                            height="98px"
                            width="208px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            prefix="+"
                            inView={inViewG2}
                            valueFrom={0}
                            valueTo={6}
                            measureUnit="%"
                            label="audi.stats_2"
                            headingLevel="1"
                            labelColor="white"
                            headingStyle={{fontWeight: 500}}
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView3}>
                        <LottieController
                            lottieJSON={Result3}
                            inView={inViewG3}
                            height="98px"
                            width="208px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            prefix="+"
                            inView={inViewG3}
                            valueFrom={0}
                            valueTo={5}
                            measureUnit="%"
                            label="audi.stats_3"
                            headingLevel="1"
                            labelColor="white"
                            headingStyle={{fontWeight: 500}}
                        />
                    </div>
                </div>
            </Results>
            <FullWidthMessage isBig intl={intl}/>
            <NextSuccessStory
                title="audi.next_success_case_title"
                desc="audi.next_success_case_description"
                link="/case-study/maui/"
                intl={intl}
            />
        </>
    );
};

const customProps = {
    localeKey: "audi", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(AudiCaseStudy));
